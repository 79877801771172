import Firebase from "../Firebase";
require("firebase/firestore");
require("firebase/auth");
export const logEntry = (documentCollection, documentId, message, oldLog) => {
    const currentUser = Firebase.auth().currentUser;
    const user = currentUser.email ? currentUser.email : currentUser.uid;
    console.log(
        "creating log entry to",
        documentCollection,
        "document:",
        documentId,
        "message:",
        message,
        "by user:",
        user
    );
    const entry = {user: user, message: message, timeStamp: Date.now()};
    const log = [...oldLog, entry];

    Firebase.firestore()
        .collection(documentCollection)
        .doc(documentId)
        .update({log: log})
        .then(() => {
            console.log("Changelog updated successfully");
        });
};
