import React from "react";
import Firebase from "./Firebase";

import {NavLink} from "react-router-dom";

const Nav = ({hideLinks}) => {
    return (
        <nav className="navbar navbar-light navbar-expand">
            {hideLinks ? (
                <></>
            ) : (
                <div className="navbar-nav">
                    <NavLink
                        exact
                        to="/tilaukset"
                        className="nav-item nav-link">
                        Tilaukset
                    </NavLink>
                    <NavLink
                        exact
                        to="/kayttajat"
                        className="nav-item nav-link">
                        Käyttäjät
                    </NavLink>
                </div>
            )}

            <div className="nav-logout">
                <button
                    className="btn ap-logout light-text"
                    onClick={(e) => {
                        e.preventDefault();
                        //console.log("Signing out");
                        Firebase.auth()
                            .signOut()
                            .then(() => {
                                console.log("Signed out");
                            })
                            .catch((error) => {
                                console.log("Error while signing out", error);
                            });
                    }}>
                    Kirjaudu ulos
                </button>
            </div>
        </nav>
    );
};

export default Nav;
