import React from "react";

const DisplayChangeLog = ({log}) => {
    return (
        <div className="row">
            <div className="col">
                <div className="edit-block shadow">
                    <p>Muutosloki</p>
                    {log.map((entry, index) => {
                        return (
                            <div className="shadow log-entry" key={index}>
                                <p>
                                    {new Date(entry.timeStamp).toLocaleString(
                                        "fi-FI"
                                    )}
                                </p>
                                <p>{entry.user}</p>
                                <p>{entry.message}</p>
                            </div>
                        );
                    })}
                </div>
            </div>
        </div>
    );
};

export default DisplayChangeLog;
