import React, {useState} from "react";
import Firebase from "../Firebase";
require("firebase/functions");

const UserInput = () => {
    const [formData, setFormData] = useState({
        email: "",
        ytunnus: "",
        address: "",
        phoneNumber: "",
        billing: "",
        eBillingAddress: "",
        eBillingProviderId: "",
        eBillingProviderName: "",
    });
    const [submitting, setSubmitting] = useState(false);
    const [feedbackText, setFeedbackText] = useState();

    const handleSubmit = () => {
        setFeedbackText();
        if (validateForm()) {
            console.log("Form valid");
            setSubmitting(true);
            const createUser = Firebase.functions("europe-west1").httpsCallable(
                "createUser"
            );

            createUser(formData)
                .then((result) => {
                    console.log("Result", result);
                    // clear form
                    clearForm();
                    setFeedbackText("Uusi käyttäjä luotu.");
                    setSubmitting(false);
                })
                .catch((error) => {
                    console.log("User Input Error", error);
                    setFeedbackText(
                        "Uuden käyttäjän luonti epäonnistui -- " + error.message
                    );
                    setSubmitting(false);
                });
        } else {
            console.log("Form invalid");
            setSubmitting(false);
        }
    };

    const validateForm = () => {
        // validate email
        // source: https://emailregex.com/ (Javascript pattern)
        const emailReg = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        const emailValid = emailReg.test(formData.email);
        if (formData.email.length === 0 || !emailValid) {
            setFeedbackText("Tarkista sähköpostiosoite");
            return false;
        }
        return true;
    };

    const clearForm = () => {
        setFormData({
            email: "",
            ytunnus: "",
            address: "",
            phoneNumber: "",
            billing: "",
            eBillingAddress: "",
            eBillingProviderId: "",
            eBillingProviderName: "",
        });
    };

    return (
        <div className="shadow user-input mb-5">
            <p>Syötä uusi käyttäjä</p>
            <form autoComplete="off">
                <div className="form-group">
                    <label htmlFor="emailInput">Sähköposti</label>
                    <input
                        type="email"
                        className="form-control"
                        id="emailInput"
                        placeholder="matti.meikalainen@gmail.com"
                        autoComplete="off"
                        value={formData.email}
                        onChange={(e) => {
                            const newData = {...formData};
                            newData.email = e.target.value;
                            setFormData(newData);
                        }}
                    />
                    <small>Salasana luodaan käyttäjälle automaattisesti</small>
                </div>

                <div className="form-group">
                    <label htmlFor="">Y-tunnus</label>
                    <input
                        type="text"
                        className="form-control"
                        id="yTunnus"
                        placeholder="1234567-8"
                        value={formData.ytunnus}
                        onChange={(e) => {
                            const newData = {...formData};
                            newData.ytunnus = e.target.value;
                            setFormData(newData);
                        }}
                    />
                </div>

                <div className="form-group">
                    <label htmlFor="address">Osoite</label>
                    <input
                        type="text"
                        className="form-control"
                        id="address"
                        placeholder="Haaparannantie 1 C 11"
                        value={formData.address}
                        onChange={(e) => {
                            const newData = {...formData};
                            newData.address = e.target.value;
                            setFormData(newData);
                        }}
                    />
                </div>

                <div className="form-group">
                    <label htmlFor="address">Puhelinnumero</label>
                    <input
                        type="text"
                        className="form-control"
                        id="phoneNumber"
                        placeholder="050123123"
                        value={formData.phoneNumber}
                        onChange={(e) => {
                            const newData = {...formData};
                            newData.phoneNumber = e.target.value;
                            setFormData(newData);
                        }}
                    />
                </div>

                <div className="form-group">
                    <label htmlFor="billing">Laskutustiedot</label>
                    <input
                        type="text"
                        className="form-control"
                        id="billing"
                        placeholder="Laskutustiedot"
                        value={formData.billing}
                        onChange={(e) => {
                            const newData = {...formData};
                            newData.billing = e.target.value;
                            setFormData(newData);
                        }}
                    />
                </div>

                <div className="form-group">
                    <label htmlFor="">Sähköiset laskutustiedot</label>
                </div>
                <div className="form-group">
                    <label htmlFor="eBillingAddress">Verkkolaskuosoite</label>
                    <input
                        type="text"
                        className="form-control"
                        id="eBillingAddress"
                        placeholder=""
                        value={formData.eBillingAddress}
                        onChange={(e) => {
                            const newData = {...formData};
                            newData.eBillingAddress = e.target.value;
                            setFormData(newData);
                        }}
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="eBillingProviderId">
                        Välittäjän tunnus
                    </label>
                    <input
                        type="text"
                        className="form-control"
                        id="eBillingProviderId"
                        placeholder=""
                        value={formData.eBillingProviderId}
                        onChange={(e) => {
                            const newData = {...formData};
                            newData.eBillingProviderId = e.target.value;
                            setFormData(newData);
                        }}
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="eBillingProviderName">Välittäjä</label>
                    <input
                        type="text"
                        className="form-control"
                        id="eBillingProviderName"
                        placeholder=""
                        value={formData.eBillingProviderName}
                        onChange={(e) => {
                            const newData = {...formData};
                            newData.eBillingProviderName = e.target.value;
                            setFormData(newData);
                        }}
                    />
                </div>
                {submitting && (
                    <div className="form-group">
                        <p>Lähettämisessä voi kestää useita sekunteja...</p>
                        <div className="spinner-border text-info"></div>
                    </div>
                )}
                {feedbackText && (
                    <div className="form-group">
                        <p>{feedbackText}</p>
                    </div>
                )}
                {!submitting && (
                    <button
                        type="submit"
                        className="btn ap-button"
                        onClick={(e) => {
                            e.preventDefault();
                            handleSubmit();
                        }}>
                        Lähetä
                    </button>
                )}
            </form>
        </div>
    );
};

export default UserInput;
