import Firebase from "./Firebase";
import FirebaseContext from "./FirebaseContext";
import {
    txCollectionPath,
    userCollectionPath,
    offerCollectionPath,
    workReportCollectionPath,
    reviewCollectionPath,
} from "./CollectionPaths";

export default Firebase;
export {
    FirebaseContext,
    txCollectionPath,
    userCollectionPath,
    offerCollectionPath,
    workReportCollectionPath,
    reviewCollectionPath,
};
