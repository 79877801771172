export interface TableData {
    refId: String;
    reporterName: String;
    locationName: String;
    address: String;
    postalCode: String;
    timeStamp: Number;
    state: Number;
    price: Number;
}

export interface SortingConfiguration {
    propertyName: string;
    sortType: SortingType;
}

export enum SortingType {
    Ascending,
    Descending,
}

export interface SortableHeaderProps {
    sortBy: (string) => void;
    sortConfig: SortingConfiguration[];
}
