import React, {useState} from "react";
import Firebase, {FirebaseContext} from "../Firebase";
import {useHistory} from "react-router-dom";
require("firebase/auth");

const ForgotPassword = (props) => {
    const [email, setEmail] = useState("");
    const [feedbackText, setFeedbackText] = useState();

    const history = useHistory();

    const validateForm = () => {
        const emailReg = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        const emailValid = emailReg.test(email);
        if (email.length === 0 || !emailValid) {
            setFeedbackText("Tarkista sähköpostiosoite");
            return false;
        }
        return true;
    };

    return (
        <div className="container login-container">
            <div className="login-box shadow">
                <form>
                    {feedbackText && (
                        <>
                            <div className="form-group">
                                <p>{feedbackText}</p>
                            </div>
                            <div className="form-group">
                                <button
                                    className="btn ap-button"
                                    onClick={(e) => {
                                        history.push("/");
                                    }}>
                                    Takaisin etusivulle
                                </button>
                            </div>
                        </>
                    )}

                    <div className="form-group">
                        <label htmlFor="inputEmail">Sähköposti</label>
                        <input
                            autoFocus
                            required
                            type="email"
                            className="form-control"
                            id="inputEmail"
                            aria-describedby="emailHelp"
                            palceholder="matti@meikalainen.com"
                            onChange={(text) => setEmail(text.target.value)}
                        />
                    </div>

                    <button
                        className="btn ap-button"
                        type="submit"
                        onClick={(e) => {
                            e.preventDefault();
                            console.log("Reset password for", email);
                            if (validateForm()) {
                                const resetPassword = Firebase.functions(
                                    "europe-west1"
                                ).httpsCallable("resetPassword");
                                resetPassword({email: email})
                                    .then((result) => {
                                        console.log("Result", result);
                                        setEmail("");
                                        setFeedbackText(
                                            "Salasanan nollaus linkki lähetetty sähköpostiin."
                                        );
                                    })
                                    .catch((error) => {
                                        console.log(
                                            "Forgot Password Error",
                                            error
                                        );
                                        setFeedbackText(
                                            "Jokin meni pieleen",
                                            error
                                        );
                                    });
                            }
                        }}>
                        Lähetä
                    </button>
                </form>
            </div>
        </div>
    );
};

export default ForgotPassword;
