import "./App.css";
import "./SelectStyles.css";
import React, {useState, useEffect} from "react";
import {Switch, Route, Redirect, useHistory} from "react-router-dom";

import Login from "./Auth/Login";
import TransactionContainer from "./sorting/TransactionContainer";
import ClientTransactionContainer from "./sorting/ClientTransactionContainer";
import Users from "./users/Users";
import Edit from "./edit/Edit";
import ForgotPassword from "./Auth/ForgotPassword";

import Firebase, {userCollectionPath} from "./Firebase";
require("firebase/auth");
require("firebase/firestore");

function App() {
    const [user, setUser] = useState(null);
    const [userData, setUserData] = useState(null);

    const history = useHistory();

    useEffect(() => {
        if (user) {
            Firebase.firestore()
                .collection(userCollectionPath)
                .doc(user.uid)
                .get()
                .then((userData) => {
                    if (userData.exists) {
                        setUserData(userData.data());
                    }
                });
        }
    }, [user]);

    useEffect(() => {
        const unsub = Firebase.auth().onAuthStateChanged((user) => {
            //console.log("Auth state change", user);
            if (user) {
                // signed in
                setUser(user);
            } else {
                //console.log("Signed out(listener)");
                // signed out
                setUser(null);
                setUserData(null);
                // back to login screen
                history.push("/");
            }
        });
        return () => {
            unsub();
        };
    }, []);

    return (
        <Switch>
            <Route path="/tilaukset">
                <TransactionContainer />
            </Route>
            <Route path="/omattilaukset">
                <ClientTransactionContainer user={user} />
            </Route>
            <Route path="/kayttajat">
                <Users />
            </Route>
            <Route
                path="/muokkaa"
                render={(props) => <Edit {...props} />}></Route>

            <Route path="/salasana-unohtui">
                <ForgotPassword />
            </Route>
            {/* If none of the previous routes render anything,
            this route acts as a fallback.

            Important: A route with path="/" will *always* match
            the URL because all URLs begin with a /. So that's
            why we put this one last of all */}
            <Route path="/">
                {userData ? (
                    userData.isAdmin ? (
                        <>
                            <Redirect to="/tilaukset" />
                        </>
                    ) : (
                        <>
                            <Redirect to="/omattilaukset" />
                        </>
                    )
                ) : (
                    <Login />
                )}
            </Route>
        </Switch>
    );
}

export default App;
