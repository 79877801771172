import React, {useState, useEffect} from "react";

import TransactionTable from "./TransactionTable";
import Header from "./Header";
import Nav from "../Nav";
import Firebase, {txCollectionPath} from "../Firebase";
require("firebase/firestore");
require("firebase/auth");

const ClientTransactionContainer = ({user}) => {
    const [transactions, setTransactions] = useState([]);
    const [filteredTransactions, setFilteredTransactions] = useState([]);
    const [reporters, setReporters] = useState([]);
    const [initializing, setInitializing] = useState(true);

    const filterCallback = (filters) => {
        //console.log("Applying filters", filters);
        let filtered = [];
        transactions.forEach((transaction) => {
            let passed = true;
            // filter bigger than postalCode
            if ("postalStart" in filters) {
                const txPostal = parseInt(transaction.postalCode);
                const fPostal = parseInt(filters.postalStart);

                if (txPostal < fPostal) {
                    passed = false;
                }
            }
            // filter smaller than postalCode
            if ("postalEnd" in filters) {
                const txPostal = parseInt(transaction.postalCode);
                const fPostal = parseInt(filters.postalEnd);
                if (txPostal > fPostal) {
                    passed = false;
                }
            }

            // filter by status
            if ("status" in filters) {
                if (filters.status.length > 0) {
                    if (!filters.status.includes(Number(transaction.state))) {
                        passed = false;
                    }
                }
            }

            // filter by reporterName
            if ("reporterName" in filters) {
                if (
                    transaction.reporterName.trim() !==
                    filters.reporterName.trim()
                ) {
                    passed = false;
                }
            }

            // text search filter "koheen nimi"
            if ("textSearch" in filters) {
                if (
                    !transaction.locationName
                        .trim()
                        .toUpperCase()
                        .includes(filters.textSearch.trim().toUpperCase())
                ) {
                    passed = false;
                }
            }
            // add to filtered list if passed filters
            if (passed) filtered.push(transaction);
        });
        setFilteredTransactions(filtered);
    };

    // return the price of an offer (if it's accepted)
    const fetchPrice = async (ref) => {
        const doc = await ref.get();
        const data = doc.data();

        if (data.accepted && data.price) {
            return data.price;
        }

        return false;
    };

    // get transactions
    const fetchTransactions = async () => {
        try {
            if (user !== null) {
                //console.log("fetching txs for user", user);
                const querySnapshot = await Firebase.firestore()
                    .collection(txCollectionPath)
                    .where("reporter", "==", user.uid)
                    .get();
                const docs = [];
                const reporters = [];
                for (const doc of querySnapshot.docs) {
                    const data = doc.data();
                    // create a postalCode field
                    // postalCode = refId with the '-uuid' removed (last 5 characters)
                    const postalCode = data.refId.substring(
                        0,
                        data.refId.length - 5
                    );
                    data["postalCode"] = postalCode;
                    // normalize timestamps, we're basically dropping hours,
                    // minutes, seconds and milliseconds from the data, to make sorting better.
                    // whole days should stay the same.
                    data.timeStamp =
                        data.timeStamp -
                        (data.timeStamp % (1000 * 60 * 60 * 24));

                    // fetch price
                    if (data.offer) {
                        const price = await fetchPrice(data.offer);
                        if (price) {
                            data["price"] = Number(price);
                        }
                    } else {
                        // 0 is treated as "falsy", a 0 will not be shown in the price column.
                        data["price"] = 0;
                    }

                    docs.push(data);
                    if (data.reporterName && data.reporterName !== "") {
                        reporters.push(data.reporterName);
                    }
                }
                return {docs: docs, reporters: reporters};
            }
        } catch (error) {
            console.log("Client TX Error", error);
        }
    };

    useEffect(() => {
        let isMounted = true;
        if (Firebase.auth().currentUser !== null) {
            fetchTransactions().then((result) => {
                // don't set data on an unmounted component
                if (isMounted) {
                    setTransactions(result?.docs);
                    // filter duplicates from reporters array
                    let reps = [...new Set(result?.reporters)];
                    let reporters = reps.map((item, index) => ({
                        name: item,
                        value: item,
                    }));
                    // prepend the array with an empty option
                    reporters.unshift({name: "", value: "empty"});
                    setReporters(reporters);
                    setFilteredTransactions(result?.docs ?? []);
                    setInitializing(false);
                }
            });
        }
        return () => {
            isMounted = false;
        };
    }, [Firebase.auth().currentUser]);

    return (
        <>
            <Nav hideLinks={true} />
            <div className="container-fluid">
                <div className="row">
                    <div className="col mt-5">
                        <Header
                            filterCallback={filterCallback}
                            reporters={reporters}
                            count={filteredTransactions.length}
                            sum={filteredTransactions.reduce(function (
                                accumulator,
                                currentValue
                            ) {
                                if (currentValue.price) {
                                    return accumulator + currentValue.price;
                                } else {
                                    return accumulator + 0;
                                }
                            },
                            0)}
                        />
                    </div>
                </div>

                <div className="row">
                    <div className="col mt-5">
                        <TransactionTable
                            transactions={filteredTransactions}
                            initializing={initializing}
                            targetLink={true}
                        />
                    </div>
                </div>
            </div>
        </>
    );
};

export default ClientTransactionContainer;
