import React, {useState, useEffect} from "react";

import ImageLister from "./ImageLister";
import {downscaleImage} from "./io";
import Firebase, {txCollectionPath} from "../Firebase";
import UserTransfer from "./UserTransfer";
import {logEntry} from "./ChangeLog";
require("firebase/storage");
require("firebase/firestore");

const EditBasicInfo = ({transaction}) => {
    const [editedTransaction, setEditedTransaction] = useState();
    // actual file objects uploaded by the user
    const [imageFiles, setImageFiles] = useState([]);
    // URLs for the files that can be used to view the image
    const [newImages, setNewImages] = useState([]);

    const [submitting, setSubmitting] = useState(false);
    const [feedbackText, setFeedbackText] = useState();

    const submitBasicInfo = async () => {
        console.log("Submitting basic info");
        const id = transaction.key;

        // upload images
        const oldAddedImages = transaction?.addedImageReference ?? [];
        // to avoid indices being the same, we need to add together the length of imageReference and addedImageReference
        let index = transaction.imageReference.length + oldAddedImages.length;
        const downloadURLs = [];
        for (let image of imageFiles) {
            const ref = Firebase.storage().ref(
                id + "/" + id + "_" + index + ".jpg"
            );
            index++;
            const result = await ref.put(image);
            const compPath =
                "gs://" +
                result.metadata.bucket +
                "/" +
                result.metadata.fullPath;
            downloadURLs.push(compPath);
        }
        console.log("new image URLs", downloadURLs);
        const doc = Firebase.firestore().collection(txCollectionPath).doc(id);
        const data = {...transaction, ...editedTransaction};

        data.addedImageReference = [...oldAddedImages, ...downloadURLs];
        doc.update(data)
            .then(() => {
                console.log("Transaction successfully updated");
                setSubmitting(false);
                setFeedbackText("Tiedot päivitetty.");
            })
            .catch((error) => {
                setFeedbackText(
                    "Virhe päivittäessä tietoja -- " + error.message
                );
                setSubmitting(false);
            });
    };

    useEffect(() => {
        function handleFiles() {
            const f = this.files;
            if (f) {
                const fs = [];
                const viewableImages = [];
                for (let file of f) {
                    //console.log("a file", file);
                    // 1Mb
                    if (file.size > 1000000) {
                        console.log("over 1Mb file");
                        // downscale images if they are over 1Mb in size
                        // a 2.5Mb file turned into a 77Kb file
                        // while testing, so I'd say it at least works
                        downscaleImage(file, (newFile) => {
                            fs.push(newFile);
                            const imageUrl = URL.createObjectURL(newFile);
                            viewableImages.push(imageUrl);
                            // merge old and new files
                            // I'd do this outside of the loop, but it won't work correctly because of
                            // the downscaleImage implementation... there probably is a way to do it though.
                            setImageFiles([...imageFiles, ...fs]);
                            setNewImages([...newImages, ...viewableImages]);
                        });
                    } else {
                        fs.push(file);
                        const imageUrl = URL.createObjectURL(file);
                        viewableImages.push(imageUrl);
                        // merge old and new files
                        setImageFiles([...imageFiles, ...fs]);
                        setNewImages([...newImages, ...viewableImages]);
                    }
                }
            } else {
                console.log("somethings wrong with the files");
            }
        }

        //console.log("attaching file handler...");
        // type="file" input is not handled by react, we're gonnna have to use
        // the file API & attach an event listener to the DOM element
        const elem = document.getElementById("basicInfoFileInput");
        if (elem) {
            elem.addEventListener("change", handleFiles, false);
            //console.log("attached file handler");
        } else {
            console.log("%ccould not attach file handler!", "color:red");
        }
        // re-run every time imageFiles change so we don't have any stale data
        // if the user adds images more than once
    }, [imageFiles, newImages]);

    // construct a google maps URL
    let mapsLink =
        "http://www.google.com/maps/search/" +
        transaction.address +
        "/@" +
        transaction.location.latitude +
        "," +
        transaction.location.longitude;
    const addedImages = transaction?.addedImageReference ?? [];
    return (
        <>
            <div className="row">
                <div className="col">
                    <div className="edit-block shadow">
                        <div className="form-group">
                            <label htmlFor="">Tilauksen tunnus</label>
                            <p>{transaction.refId}</p>
                        </div>
                        <div className="form-group">
                            <label htmlFor="">Tilaajan nimi</label>
                            <input
                                type="text"
                                className="form-control"
                                value={
                                    editedTransaction?.reporterName ??
                                    transaction.reporterName
                                }
                                onChange={(e) => {
                                    const value = e.target.value;
                                    const edited = {
                                        ...editedTransaction,
                                    };
                                    edited.reporterName = value;
                                    setEditedTransaction(edited);
                                }}
                            />
                            {/* tranfer transaction to another user*/}
                            {<UserTransfer transaction={transaction} />}
                        </div>
                        <div className="form-group">
                            <label htmlFor="">Yrityksen nimi</label>
                            <input
                                type="text"
                                className="form-control"
                                value={
                                    editedTransaction?.reporterCompany ??
                                    transaction.reporterCompany
                                }
                                onChange={(e) => {
                                    const value = e.target.value;
                                    const edited = {
                                        ...editedTransaction,
                                    };
                                    edited.reporterCompany = value;
                                    setEditedTransaction(edited);
                                }}
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="">Puhelinnumero</label>
                            <input
                                type="text"
                                className="form-control"
                                value={
                                    editedTransaction?.phoneNumber ??
                                    transaction.phoneNumber
                                }
                                onChange={(e) => {
                                    const value = e.target.value;
                                    const edited = {
                                        ...editedTransaction,
                                    };
                                    edited.phoneNumber = value;
                                    setEditedTransaction(edited);
                                }}
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="">Sähköposti</label>
                            <input
                                type="text"
                                className="form-control"
                                value={
                                    editedTransaction?.email ??
                                    transaction.email
                                }
                                onChange={(e) => {
                                    const value = e.target.value;
                                    const edited = {
                                        ...editedTransaction,
                                    };
                                    edited.email = value;
                                    setEditedTransaction(edited);
                                }}
                            />
                        </div>
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col">
                    <div className="edit-block shadow">
                        <div className="form-group">
                            <label htmlFor="">{transaction.locationName}</label>
                        </div>
                        <div className="form-group">
                            <label htmlFor="">Osoite</label>
                            <input
                                type="text"
                                className="form-control"
                                value={
                                    editedTransaction?.address ??
                                    transaction.address
                                }
                                onChange={(e) => {
                                    const value = e.target.value;
                                    const edited = {
                                        ...editedTransaction,
                                    };
                                    edited.address = value;
                                    setEditedTransaction(edited);
                                }}
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="">Postinumero</label>
                            <input
                                type="text"
                                className="form-control"
                                value={
                                    editedTransaction?.postalCode ??
                                    transaction.postalCode
                                }
                                onChange={(e) => {
                                    const value = e.target.value;
                                    const edited = {
                                        ...editedTransaction,
                                    };
                                    edited.postalCode = value;
                                    setEditedTransaction(edited);
                                }}
                            />
                        </div>
                        <div className="form-group">
                            <a
                                href={mapsLink}
                                target="_blank"
                                rel="noreferrer"
                                className="orange-text">
                                Sijainti - Katso kartalta
                            </a>
                        </div>
                        <div className="form-group">
                            <label htmlFor="">Lisätiedot</label>
                            <textarea
                                className="form-control"
                                value={
                                    editedTransaction?.additionalInfo ??
                                    transaction.additionalInfo
                                }
                                rows={3}
                                onChange={(e) => {
                                    const value = e.target.value;
                                    const edited = {
                                        ...editedTransaction,
                                    };
                                    edited.additionalInfo = value;
                                    setEditedTransaction(edited);
                                }}
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="">Kuvat kohteesta</label>
                        </div>
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col">
                    <div className="edit-block image-block">
                        <ImageLister
                            imageReferences={[...transaction.imageReference]}
                        />
                        {addedImages.length > 0 && (
                            <>
                                <p style={{marginTop: "7px"}}>
                                    Muokatut kuvat:
                                </p>
                                <ImageLister imageReferences={addedImages} />
                            </>
                        )}

                        {newImages.length > 0 && (
                            <>
                                <p style={{marginTop: "7px"}}>Lisätyt kuvat:</p>
                                <ImageLister staticImages={newImages} />
                            </>
                        )}

                        <button
                            className="btn ap-button"
                            onClick={(e) => {
                                const elem = document.getElementById(
                                    "basicInfoFileInput"
                                );
                                if (elem) {
                                    elem.click();
                                } else {
                                    console.log(
                                        "%cfile input not found!",
                                        "color:red"
                                    );
                                }
                            }}>
                            Lisää kuvia
                        </button>
                        <input
                            type="file"
                            multiple // user can select multiple files
                            accept="image/*" // accept only images
                            id="basicInfoFileInput"
                            style={{display: "none"}}
                        />
                    </div>
                </div>
            </div>
            {submitting && (
                <div className="form-group text-center">
                    <div className="spinner-border text-info"></div>
                </div>
            )}
            {feedbackText && (
                <div className="form-group text-center">
                    <p>{feedbackText}</p>
                </div>
            )}
            {!submitting &&
                (newImages.length > 0 ||
                    (editedTransaction &&
                        Object.keys(editedTransaction).length > 0)) && (
                    <div className="row">
                        <div className="col submit-col mb-3">
                            <button
                                id="basicInfoSubmit"
                                onClick={(e) => {
                                    e.preventDefault();
                                    setFeedbackText();
                                    setSubmitting(true);
                                    submitBasicInfo();
                                }}
                                className="btn ap-button">
                                Tallenna muutokset perustietoihin
                            </button>
                        </div>
                    </div>
                )}
        </>
    );
};

export default EditBasicInfo;
