import React, {useState} from "react";
import SelectSearch from "react-select-search";
import {DownOutlined, UpOutlined} from "@ant-design/icons";

const statusMap = {
    1: "Luotu",
    1.01: "Luonnos",
    1.1: "Tarjous tehty",
    1.15: "Hylätty",
    1.2: "Hyväksytty - Odottaa työtä",
    2: "Työn alla",
    3: "Valmis",
};

const options = [];
for (let property in statusMap) {
    // convert to number so we can sort the array properly
    const n = Number(property);
    options.push(n);
}
options.sort();

const Filter = ({filterCallback, reporters}) => {
    const [filters, setFilters] = useState({status: []});
    const [showStatusFilters, setShowStatusFilters] = useState(false);
    //console.log("Filters", filters);
    //console.log("reporters", reporters);
    const [collapsed, setCollapsed] = useState(false);

    const onStatusSelected = (event) => {
        const checked = event.target.checked;
        const value = Number(event.target.value);
        const newFilters = {...filters};
        if (checked) {
            newFilters.status = [...newFilters.status, value];
        } else {
            newFilters.status = newFilters.status.filter(
                (status) => status !== value
            );
        }

        setFilters(newFilters);
    };

    return (
        <>
            <div className="row">
                <div className="col filter-col">
                    <button
                        className="btn collapse-btn"
                        onClick={() => setCollapsed(!collapsed)}>
                        Suodata {collapsed ? <UpOutlined /> : <DownOutlined />}
                    </button>
                </div>
            </div>
            <div
                className={
                    collapsed
                        ? "filter-collapse collapse hide"
                        : "filter-collapse collapse show"
                }
                id="filterCollapse">
                <div className="row">
                    <div className="col">
                        <form className="form-group row" autoComplete="off">
                            <div className="form-group mx-2">
                                <label htmlFor="textSearch">Tekstihaku</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Kirjoita"
                                    id="textSearch"
                                    autoComplete="off"
                                    onChange={(e) => {
                                        const newFilters = {...filters};
                                        newFilters.textSearch = e.target.value;
                                        setFilters(newFilters);
                                    }}
                                />
                            </div>
                            <div className="form-group mx-2">
                                <label htmlFor="clientSearch">Tilaaja</label>
                                <SelectSearch
                                    options={reporters}
                                    placeholder="Valitse"
                                    search={true}
                                    onChange={(value) => {
                                        const newFilters = {...filters};
                                        if (value === "empty") {
                                            delete newFilters.reporterName;
                                        } else {
                                            newFilters.reporterName = value;
                                        }
                                        setFilters(newFilters);
                                    }}
                                />
                            </div>
                            <div className="form-group mx-2">
                                <label htmlFor="pStart">Postinumero</label>
                                <div className="row">
                                    <div className="col">
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="00000"
                                            id="pStart"
                                            autoComplete="off"
                                            onChange={(txt) => {
                                                const newFilters = {...filters};
                                                newFilters.postalStart =
                                                    txt.target.value;
                                                setFilters(newFilters);
                                            }}
                                        />
                                    </div>
                                    <p
                                        style={{
                                            textAlign: "center",
                                            margin: 0,
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                        }}>
                                        {"-"}
                                    </p>
                                    <div className="col">
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="99999"
                                            id="pEnd"
                                            autoComplete="off"
                                            onChange={(txt) => {
                                                const newFilters = {...filters};
                                                newFilters.postalEnd =
                                                    txt.target.value;
                                                setFilters(newFilters);
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="form-group mx-2">
                                <label>Status</label>
                                <div className="row">
                                    <div
                                        className={
                                            showStatusFilters
                                                ? "col check-col"
                                                : "col"
                                        }>
                                        {showStatusFilters &&
                                            options.map((option, index) => {
                                                return (
                                                    <div
                                                        key={
                                                            "statusOption" +
                                                            index
                                                        }
                                                        className="check-wrapper">
                                                        <div className="form-check">
                                                            <input
                                                                className="form-check-input"
                                                                type="checkbox"
                                                                value={option}
                                                                onClick={
                                                                    onStatusSelected
                                                                }
                                                                id={
                                                                    "statusOption" +
                                                                    index
                                                                }
                                                            />
                                                            <label
                                                                className="form-check-label"
                                                                htmlFor={
                                                                    "statusOption" +
                                                                    index
                                                                }>
                                                                {
                                                                    statusMap[
                                                                        option
                                                                    ]
                                                                }
                                                            </label>
                                                        </div>
                                                    </div>
                                                );
                                            })}
                                        {!showStatusFilters && (
                                            <button
                                                className="btn status-button"
                                                onClick={(e) => {
                                                    setShowStatusFilters(true);
                                                }}>
                                                Valitse
                                            </button>
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div className="form-group row">
                                <div className="col mt-auto">
                                    <button
                                        type="submit"
                                        className="btn ap-button"
                                        onClick={(e) => {
                                            e.preventDefault();

                                            filterCallback(filters);
                                        }}>
                                        Suodata
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Filter;
