export const downscaleImage = (image, callback) => {
    const fileName = image.name;
    const mime = image.type;
    const reader = new FileReader();
    reader.readAsDataURL(image);
    reader.onload = (event) => {
        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");
        let img = new Image();
        img.src = event.target.result;

        // I'm gonna be honest and say I don't really understand how this resizing works...
        // it's weird that we're using 2 canvases, but I think the purpose is
        // down-stepping, as explained here: https://stackoverflow.com/questions/19262141/resize-image-with-javascript-canvas-smoothly
        img.onload = function () {
            canvas.width = 1080;
            canvas.height = canvas.width * (img.height / img.width);
            let oc = document.createElement("canvas"),
                octx = oc.getContext("2d");

            oc.width = img.width;
            oc.height = img.height;
            octx.drawImage(img, 0, 0, oc.width, oc.height);

            octx.drawImage(oc, 0, 0, oc.width * 0.5, oc.height * 0.5);

            ctx.drawImage(
                oc,
                0,
                0,
                oc.width * 0.5,
                oc.height * 0.5,
                0,
                0,
                canvas.width,
                canvas.height
            );

            ctx.canvas.toBlob(
                (blob) => {
                    console.log("blob", blob);
                    const file = new File([blob], fileName, {
                        type: mime,
                        lastModified: Date.now(),
                    });
                    console.log("new file", file);
                    callback(file);
                },
                mime, //keep mime type
                0.5 //quality
            );
        };
    };
};
