import React from "react";

const UserFilter = ({onUserFilter}) => {
    return (
        <>
            <form className="form-group user-filter">
                <div className="form-group mx-2">
                    <label htmlFor="textSearch">
                        Filtteröi nimellä tai sähköpostilla
                    </label>
                    <input
                        type="text"
                        className="form-control"
                        placeholder="Kirjoita"
                        id="textSearch"
                        autoComplete="off"
                        onChange={(e) => {
                            onUserFilter(e.target.value);
                        }}
                    />
                </div>
            </form>
        </>
    );
};

export default UserFilter;
