import React, {useEffect, useState} from "react";

import Nav from "../Nav";
import Firebase, {txCollectionPath} from "../Firebase";
import EditBasicInfo from "./EditBasicInfo";
import EditOffer from "./EditOffer";
import EditReport from "./EditReport";
import EditReview from "./EditReview";
import DisplayChangeLog from "./DisplayChangeLog";

const Edit = ({location}) => {
    const searchParams = new URLSearchParams(location.search);
    const params = location.state;
    let transactionId = false;
    // normally transactionId would come from state params
    // but if we're refreshing the page, that is not possible
    // so we get it from the URL query parameters instead (if possible)
    if (!params) {
        if (searchParams.has("transaction")) {
            transactionId = searchParams.get("transaction");
        }
    } else {
        transactionId = params.transaction;
    }

    const [transaction, setTransaction] = useState();
    const [offer, setOffer] = useState();
    const [workReport, setWorkReport] = useState();
    const [review, setReview] = useState();

    const [errorText, setErrorText] = useState();

    const handleSubmit = (e) => {
        e.preventDefault();
        // TODO: submit all data at once?
        // right now updating the transaction is split into different sections
        // keep unchanged data the same, but overwrite any changed data
        //const newTx = {...transaction, ...editedTransaction};
        //console.log("Merged transaction", newTx);
    };

    // fetch the transaction
    useEffect(() => {
        if (transactionId) {
            // subscribe to the document for updates
            Firebase.firestore()
                .collection(txCollectionPath)
                .doc(transactionId)
                .onSnapshot((snapshot) => {
                    if (snapshot && snapshot.data()) {
                        //console.log("Edit snapshot");
                        setTransaction(snapshot.data());
                    } else {
                        //console.log("Tilausta ei löytynyt :(");
                        setErrorText("Tilausta ei löytynyt :(");
                    }
                });
        } else {
            console.log("No transaction id set");
            setErrorText("Tilausta ei löytynyt :(");
        }
    }, [transactionId]);

    // fetch attached references
    useEffect(() => {
        if (transaction) {
            //console.log("Transaction exists, fetching stuff");
            if (transaction.offer && !offer) {
                updateData(transaction.offer, "offer");
            } else if (transaction.workReport && !workReport) {
                updateData(transaction.workReport, "workReport");
            } else if (transaction.review && !review) {
                updateData(transaction.review, "review");
            } else {
                //console.log("nothing else to fetch!");
                // initialization completed
            }
        }
    }, [transaction, offer, workReport, review]);

    // handle offer, workReport & review references
    // and updates the state of those as well
    function updateData(reference, type) {
        //console.log("fetching", type);
        reference.get().then((documentSnapshot) => {
            if (documentSnapshot.exists) {
                const data = documentSnapshot.data();
                if (type === "offer" && !offer) {
                    setOffer(data);
                } else if (type === "workReport" && !workReport) {
                    setWorkReport(data);
                } else if (type === "review" && !review) {
                    setReview(data);
                } else {
                    console.log("unknown type or tried again", type);
                }
            }
        });
    }

    //console.log("tx", transaction);
    if (errorText) {
        return (
            <>
                <Nav />

                <h2
                    className="mt-5"
                    style={{margin: "auto", textAlign: "center"}}>
                    {errorText}
                </h2>
            </>
        );
    }
    if (transaction) {
        // create buttons for offer & workReport creation if needed
        let actionButton = false;
        if (!transaction.offer) {
            console.log("could create offer");
            actionButton = (
                <div className="form-group text-center">
                    <button
                        onClick={(e) => {
                            console.log("create offer...");
                            setOffer({
                                accepted: null,
                                deliveryDate: "",
                                imageReference: [],
                                key: "",
                                mainContent: "",
                                otherContent: "",
                                otherServiceImages: [],
                                otherServices: [],
                                price: "",
                                timeStamp: Date.now(),
                            });
                        }}
                        className="btn ap-button">
                        Luo tarjous
                    </button>
                </div>
            );
        }
        if (
            transaction.offer &&
            offer &&
            offer.accepted &&
            !transaction.workReport
        ) {
            console.log("could create workreport");
            actionButton = (
                <div className="form-group text-center">
                    <button
                        onClick={(e) => {
                            console.log("create workReport...");
                            setWorkReport({
                                imageReference: [],
                                key: "",
                                mainContent: "",
                                otherContent: "",
                            });
                        }}
                        className="btn ap-button">
                        Luo työraportti
                    </button>
                </div>
            );
        }
        return (
            <>
                <Nav />
                <div className="container-fluid edit mb-5">
                    {transaction && <EditBasicInfo transaction={transaction} />}
                    {offer && (
                        <EditOffer offer={offer} transaction={transaction} />
                    )}
                    {workReport && (
                        <EditReport
                            workReport={workReport}
                            transaction={transaction}
                        />
                    )}
                    {review && <EditReview review={review} />}
                    {actionButton}
                    {transaction?.log && (
                        <DisplayChangeLog log={transaction?.log} />
                    )}
                </div>
            </>
        );
    }
    return (
        <>
            <Nav />
            <p>Ladataan...</p>
        </>
    );
};

export default Edit;
