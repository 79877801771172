import React from "react";
import {TableHead, TableRow, TableCell} from "@material-ui/core";
import {ArrowUpOutlined, ArrowDownOutlined} from "@ant-design/icons";
import {
    TableData,
    SortingConfiguration,
    SortingType,
    SortableHeaderProps,
} from "./SortingTypes";

const SortableHeader = ({sortBy, sortConfig}: SortableHeaderProps) => {
    const tableColumn = [
        {label: "Tunnus", property: "refId"},
        {label: "Tilaaja", property: "reporterCompany"}, // for some reason these two need to be 
        {label: "Yritys", property: "reporterName"},     // "the wrong way around"
        {label: "Kohteen nimi", property: "locationName"},
        {label: "Osoite", property: "address"},
        {label: "Postinumero", property: "postalCode"},
        {label: "Tilausaika", property: "timeStamp"},
        {label: "Status", property: "state"},
        {label: "Hinta", property: "price"},
        {label: "Avaa"},
    ];

    const getSortDirection = (property) => {
        var config = sortConfig.find(
            (sortConfig) => sortConfig.propertyName === property
        );
        return config ? (
            config.sortType === SortingType.Descending ? (
                <ArrowDownOutlined />
            ) : (
                <ArrowUpOutlined />
            )
        ) : null;
    };

    return (
        <TableHead>
            <TableRow>
                {tableColumn.map((column, index) => {
                    if ("property" in column) {
                        return (
                            <TableCell
                                key={index}
                                onClick={() => sortBy(column.property)}>
                                <div>
                                    {column.label}{" "}
                                    {getSortDirection(column.property)}
                                </div>
                            </TableCell>
                        );
                    } else {
                        return (
                            <TableCell key={index}>
                                <div>{column.label}</div>
                            </TableCell>
                        );
                    }
                })}
            </TableRow>
        </TableHead>
    );
};

export default SortableHeader;
