import React, {useState, useEffect} from "react";

import {Switch} from "@material-ui/core";

import Firebase, {userCollectionPath} from "../Firebase";
require("firebase/firestore");
require("firebase/auth");

const UserDetails = ({user, onSelectedUserUpdated}) => {
    const [editedUser, setEditedUser] = useState({});
    const [feedbackText, setFeedbackText] = useState();
    const [submitting, setSubmitting] = useState(false);

    const updateAdmin = (bool) => {
        //console.log("Updating user", user.email, "to", bool);

        Firebase.firestore()
            .collection(userCollectionPath)
            .doc(user.uid)
            .update({
                isAdmin: bool,
            })
            .then(() => {
                console.log("User updated successfully");
            })
            .catch((error) => {
                console.log("Error while updating user", error);
                // roll back changes in the client
                const usr = {...user};
                usr.isAdmin = !bool;
                onSelectedUserUpdated(usr);
                setFeedbackText(
                    "Virhe päivittäessä pääkäyttäjä statusta -- " +
                        error.message
                );
            });

        const usr = {...user};
        usr.isAdmin = bool;
        onSelectedUserUpdated(usr);
    };

    const updateUserData = (e) => {
        e.preventDefault();
        setSubmitting(true);
        //console.log("Updating user", user.email, user.uid);
        Firebase.firestore()
            .collection(userCollectionPath)
            .doc(user.uid)
            .update(editedUser)
            .then(() => {
                console.log("User updated successfully");
                setSubmitting(false);
                setFeedbackText("Käyttäjän tiedot päivitetty.");
            })
            .catch((error) => {
                setSubmitting(false);
                setFeedbackText(
                    "Virhe päivittäessä käyttäjän tietoja -- " + error.message
                );
                console.log("Error while updating user", error);
                // roll back changes in the client
                const usr = {...user};
                onSelectedUserUpdated(usr);
            });
    };

    // reset EditedUser when a new user is selected
    useEffect(() => {
        setFeedbackText();
        setEditedUser({});
    }, [user]);

    if (user) {
        return (
            <div className="card shadow">
                <div className="card-body">
                    <h5 className="card-title">{user.email}</h5>
                    <div className="card-row">
                        <p>Tunnus</p>
                        <p>{user.uid}</p>
                    </div>
                    <div className="card-row">
                        <p>Nimi</p>
                        <p>{user.name ?? ""}</p>
                    </div>
                    <div className="card-row">
                        <p>Pääkäyttäjä</p>
                        <div>
                            {" "}
                            {user.isAdmin ? "Kyllä" : "Ei"}
                            {user.uid === Firebase.auth().currentUser.uid ? (
                                // disable updating yourself as admin/not admin
                                <Switch
                                    checked={user.isAdmin}
                                    disabled
                                    onChange={() => {}}
                                />
                            ) : (
                                <Switch
                                    checked={user.isAdmin}
                                    onChange={() => {
                                        updateAdmin(!user.isAdmin);
                                    }}
                                />
                            )}
                        </div>
                    </div>
                    <div className="card-row">
                        <p>Y-tunnus</p>
                        <input
                            type="text"
                            className="form-control"
                            value={
                                editedUser?.ytunnus
                                    ? editedUser.ytunnus
                                    : user.ytunnus
                                    ? user.ytunnus
                                    : ""
                            }
                            onChange={(e) => {
                                const value = e.target.value;
                                const edited = {
                                    ...editedUser,
                                };
                                edited.ytunnus = value;
                                setEditedUser(edited);
                            }}
                        />
                    </div>
                    <div className="card-row">
                        <p>Puhelinnumero</p>
                        <input
                            type="text"
                            className="form-control"
                            value={
                                editedUser?.phoneNumber
                                    ? editedUser.phoneNumber
                                    : user.phoneNumber
                                    ? user.phoneNumber
                                    : ""
                            }
                            onChange={(e) => {
                                const value = e.target.value;
                                const edited = {
                                    ...editedUser,
                                };
                                edited.phoneNumber = value;
                                setEditedUser(edited);
                            }}
                        />
                    </div>
                    <div className="card-row">
                        <p>Osoite</p>
                        <input
                            type="text"
                            className="form-control"
                            value={
                                editedUser?.address
                                    ? editedUser.address
                                    : user.address
                                    ? user.address
                                    : ""
                            }
                            onChange={(e) => {
                                const value = e.target.value;
                                const edited = {
                                    ...editedUser,
                                };
                                edited.address = value;
                                setEditedUser(edited);
                            }}
                        />
                    </div>
                    <div className="card-row">
                        <p>Laskutustiedot</p>
                        <input
                            type="text"
                            className="form-control"
                            value={
                                editedUser?.billing
                                    ? editedUser.billing
                                    : user.billing
                                    ? user.billing
                                    : ""
                            }
                            onChange={(e) => {
                                const value = e.target.value;
                                const edited = {
                                    ...editedUser,
                                };
                                edited.billing = value;
                                setEditedUser(edited);
                            }}
                        />
                    </div>
                    <div className="card-row">
                        <p>Sähköiset laskutustiedot</p>
                    </div>
                    <div className="card-row">
                        <p>Verkkolaskuosoite</p>
                        <input
                            type="text"
                            className="form-control"
                            value={
                                editedUser?.eBillingAddress
                                    ? editedUser.eBillingAddress
                                    : user.eBillingAddress
                                    ? user.eBillingAddress
                                    : ""
                            }
                            onChange={(e) => {
                                const value = e.target.value;
                                const edited = {
                                    ...editedUser,
                                };
                                edited.eBillingAddress = value;
                                setEditedUser(edited);
                            }}
                        />
                    </div>
                    <div className="card-row">
                        <p>Välittäjän tunnus</p>
                        <input
                            type="text"
                            className="form-control"
                            value={
                                editedUser?.eBillingProviderId
                                    ? editedUser.eBillingProviderId
                                    : user.eBillingProviderId
                                    ? user.eBillingProviderId
                                    : ""
                            }
                            onChange={(e) => {
                                const value = e.target.value;
                                const edited = {
                                    ...editedUser,
                                };
                                edited.eBillingProviderId = value;
                                setEditedUser(edited);
                            }}
                        />
                    </div>
                    <div className="card-row">
                        <p>Sähköiset laskutustiedot</p>
                        <input
                            type="text"
                            className="form-control"
                            value={
                                editedUser?.eBillingProviderName
                                    ? editedUser.eBillingProviderName
                                    : user.eBillingProviderName
                                    ? user.eBillingProviderName
                                    : ""
                            }
                            onChange={(e) => {
                                const value = e.target.value;
                                const edited = {
                                    ...editedUser,
                                };
                                edited.eBillingProviderName = value;
                                setEditedUser(edited);
                            }}
                        />
                    </div>
                    {submitting && (
                        <div className="form-group text-center mt-3">
                            <div className="spinner-border text-info"></div>
                        </div>
                    )}
                    {feedbackText && (
                        <div className="form-group text-center">
                            <p>{feedbackText}</p>
                        </div>
                    )}
                    {!submitting && Object.keys(editedUser).length > 0 && (
                        <div className="card-row">
                            <button
                                onClick={updateUserData}
                                className="btn ap-button">
                                Tallenna muutokset
                            </button>
                        </div>
                    )}
                </div>
            </div>
        );
    } else {
        return <></>;
    }
};

export default UserDetails;
