import React from "react";

import Filter from "./Filter";
import Firebase from "../Firebase";
import logo from "../asfalttipartio.jpg";

const Header = ({filterCallback, reporters, count, sum}) => {
    return (
        <>
            <div className="header">
                <div className="left-block block">
                    <img className="logo" src={logo} alt="" />
                    <h1 className="light-text">Tilaukset</h1>
                </div>
                <div className="right-block block">
                    <p className="light-text header-stats">
                        <span>Näytetään:</span> {count} tilausta -{" "}
                        {sum.toLocaleString().replaceAll(",", " ")} €
                    </p>
                </div>
            </div>
            <Filter filterCallback={filterCallback} reporters={reporters} />
        </>
    );
};

export default Header;
