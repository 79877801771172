import React, {useState, useEffect} from "react";
import {Modal} from "react-bootstrap";
import SelectSearch from "react-select-search";
import Firebase, {userCollectionPath, txCollectionPath} from "../Firebase";
import {logEntry} from "./ChangeLog";
require("firebase/firestore");

const UserTransfer = ({transaction}) => {
    const [show, setShow] = useState(false);
    const [users, setUsers] = useState([]);
    const [selectedUser, setSelectedUser] = useState();
    const [feedbackText, setFeedbackText] = useState();
    const [submitting, setSubmitting] = useState(false);

    useEffect(() => {
        let subscriber;
        if (show) {
            subscriber = Firebase.firestore()
                .collection(userCollectionPath)
                .onSnapshot((snapshot) => {
                    const usr = [];
                    if (snapshot) {
                        snapshot.forEach((user) => {
                            const data = user.data();
                            // filter anonymous users out
                            if (data.email) {
                                let user = {
                                    name: data.email,
                                    id: data.uid,
                                    value: data.uid,
                                };
                                usr.push(user);
                            }
                        });
                        setUsers(usr);
                    }
                });
        }
        return () => {
            if (subscriber) {
                subscriber();
            }
        };
    }, [show, transaction]);

    const transferTransaction = async () => {
        console.log(
            "Transfering transaction",
            transaction.key,
            "to user",
            selectedUser
        );
        const oldReporter = transaction.email;
        // get selected user email
        const userDoc = await Firebase.firestore()
            .collection(userCollectionPath)
            .doc(selectedUser)
            .get();
        const userData = userDoc.data();
        const payload = {reporter: selectedUser};
        if (userData.email) {
            payload.email = userData.email;
        }
        console.log("Transfer payload", payload);
        setSubmitting(true);
        Firebase.firestore()
            .collection(txCollectionPath)
            .doc(transaction.key)
            .update(payload)
            .then(() => {
                console.log("Transaction transferred");
                setSubmitting(false);
                setFeedbackText("Siirto onnistui.");
                logEntry(
                    txCollectionPath,
                    transaction.key,
                    "Käyttäjä vaihdettu " + // message
                        oldReporter +
                        " => " +
                        userData.email ?? "",
                    transaction?.log ?? [] // append old log (if exists)
                );
            })
            .catch((error) => {
                console.log("Error while transfering transaction", error);
                setSubmitting(false);
                setFeedbackText("Siirto epäonnistui. " + error.message);
            });
    };

    return (
        <>
            <button
                className="btn ap-button mt-2"
                onClick={() => setShow(true)}>
                Siirrä tilaus toiselle käyttäjälle...
            </button>

            <Modal
                show={show}
                onHide={() => {
                    setShow(false);
                    if (feedbackText) {
                        setFeedbackText();
                    }
                }}>
                <Modal.Body>
                    <div className="row">
                        <div className="col text-center">
                            <p>Siirrä tilaus toiselle käyttäjälle</p>
                            <p>
                                Tämä mahdollistaa käyttäjän nähdä tämä tilaus
                                omassa hallintapaneelissa ja applikaatiossa.
                            </p>
                            <SelectSearch
                                options={users}
                                placeholder="Valitse käyttäjä"
                                search={true}
                                onChange={(value) => {
                                    setSelectedUser(value);
                                }}
                            />
                            {submitting && (
                                <div className="form-group text-center">
                                    <div className="spinner-border text-info"></div>
                                </div>
                            )}
                            {feedbackText && (
                                <div className="form-group text-center">
                                    <p>{feedbackText}</p>
                                </div>
                            )}
                            {selectedUser && !submitting && (
                                <button
                                    className="btn ap-button mt-3"
                                    onClick={transferTransaction}>
                                    Siirrä tilaus valitulle käyttäjälle
                                </button>
                            )}
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default UserTransfer;
