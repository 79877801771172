import React, {useState, useEffect} from "react";

import ImageLister from "./ImageLister";
import Firebase, {
    txCollectionPath,
    workReportCollectionPath,
} from "../Firebase";
import {downscaleImage} from "./io";
import {logEntry} from "./ChangeLog";
import {COMPLETED} from "./TransactionState";
require("firebase/firestore");

const EditReport = ({workReport, transaction}) => {
    const [editedWorkReport, setEditedWorkReport] = useState();

    const [submitting, setSubmitting] = useState(false);
    const [feedbackText, setFeedbackText] = useState();

    // actual file objects uploaded by the user
    const [imageFiles, setImageFiles] = useState([]);
    // URLs for the files that can be used to view the image
    const [newImages, setNewImages] = useState([]);

    // creating a new report this way will skip INPROGRESS state
    // but it won't cause any problems.
    const submitReport = async () => {
        setSubmitting(false);
        let id =
            workReport.key.length > 0
                ? workReport.key
                : Firebase.firestore()
                      .collection(workReportCollectionPath)
                      .doc().id;
        console.log("report id", id);
        const oldImages = workReport.imageReference;
        const downloadURLs = [];
        let index = oldImages.length;
        for (let image of imageFiles) {
            const ref = Firebase.storage().ref(
                id + "/" + id + "_" + index + ".jpg"
            );
            index++;
            const result = await ref.put(image);
            const compPath =
                "gs://" +
                result.metadata.bucket +
                "/" +
                result.metadata.fullPath;
            downloadURLs.push(compPath);
        }
        console.log("new image URLs", downloadURLs);
        const doc = Firebase.firestore()
            .collection(workReportCollectionPath)
            .doc(id);
        const data = {...workReport, ...editedWorkReport};
        data.timeStamp = Date.now();
        data.key = id;

        data.imageReference = [...oldImages, ...downloadURLs];
        doc.set(data)
            .then(() => {
                console.log("Workreport successfully updated.");
                // update ref to transaction and update state
                updateReferenceAndState(transaction.key, doc, COMPLETED);
                // log entry
                logEntry(
                    txCollectionPath,
                    transaction.key,
                    "Työraportti luotu",
                    transaction?.log ?? []
                );
                setSubmitting(false);
                setFeedbackText("Tiedot päivitetty.");
            })
            .catch((error) => {
                setFeedbackText(
                    "Virhe päivittäessä tietoja -- " + error.message
                );
                setSubmitting(false);
            });
    };

    const updateReferenceAndState = (txId, ref, state) => {
        Firebase.firestore()
            .collection(txCollectionPath)
            .doc(txId)
            .update({workReport: ref, state: state})
            .then(() => {
                console.log("Transaction reference updated");
            })
            .catch((error) => {
                console.log(
                    "Error while updating transaction reference",
                    error
                );
            });
    };

    useEffect(() => {
        function handleFiles() {
            const f = this.files;
            if (f) {
                const fs = [];
                const viewableImages = [];
                console.log("offer files", f);
                for (let file of f) {
                    //console.log("a file", file);
                    // 1Mb
                    if (file.size > 1000000) {
                        console.log("over 1Mb file");
                        // downscale images if they are over 1Mb in size
                        // a 2.5Mb file turned into a 77Kb file
                        // while testing, so I'd say it at least works
                        downscaleImage(file, (newFile) => {
                            fs.push(newFile);
                            const imageUrl = URL.createObjectURL(newFile);
                            viewableImages.push(imageUrl);
                            // merge old and new files
                            // I'd do this outside of the loop, but it won't work correctly because of
                            // the downscaleImage implementation... there probably is a way to do it though.
                            setImageFiles([...imageFiles, ...fs]);
                            setNewImages([...newImages, ...viewableImages]);
                        });
                    } else {
                        fs.push(file);
                        const imageUrl = URL.createObjectURL(file);
                        viewableImages.push(imageUrl);
                        // merge old and new files
                        setImageFiles([...imageFiles, ...fs]);
                        setNewImages([...newImages, ...viewableImages]);
                    }
                }
            } else {
                console.log("somethings wrong with the files");
            }
        }
        //console.log("attaching file handler (report)");
        // type="file" input is not handled by react, we're gonnna have to use
        // the file API & attach an event listener to the DOM element
        const elem = document.getElementById("reportFileInput");
        if (elem) {
            elem.addEventListener("change", handleFiles, false);
            //console.log("attached file handler");
        } else {
            console.log("%ccould not attach file handler!", "color:red");
        }
        // re-run every time imageFiles change so we don't have any stale data
        // if the user adds images more than once
    }, [imageFiles, newImages]);

    return (
        <>
            <div className="row">
                <div className="col">
                    <div className="edit-block shadow">
                        <div className="form-group">
                            <label htmlFor="">Työraportti</label>
                        </div>
                        <div className="form-group">
                            <label htmlFor="">Korjauksen tiedot</label>
                            <textarea
                                className="form-control"
                                value={
                                    editedWorkReport?.mainContent ??
                                    workReport.mainContent
                                }
                                onChange={(e) => {
                                    const value = e.target.value;

                                    const edited = {...editedWorkReport};
                                    edited.mainContent = value;
                                    setEditedWorkReport(edited);
                                }}
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="">Muiden palveluiden tiedot</label>
                            <textarea
                                className="form-control"
                                value={
                                    editedWorkReport?.otherContent ??
                                    workReport.otherContent
                                }
                                onChange={(e) => {
                                    const value = e.target.value;

                                    const edited = {...editedWorkReport};
                                    edited.otherContent = value;
                                    setEditedWorkReport(edited);
                                }}
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="">Kuvat korjauksista</label>
                        </div>
                    </div>
                    <div className="edit-block image-block">
                        <ImageLister
                            imageReferences={workReport.imageReference}
                        />
                        {newImages.length > 0 && (
                            <>
                                <p style={{marginTop: "7px"}}>Lisätyt kuvat:</p>
                                <ImageLister staticImages={newImages} />
                            </>
                        )}
                        <button
                            className="btn ap-button"
                            onClick={(e) => {
                                const elem = document.getElementById(
                                    "reportFileInput"
                                );
                                if (elem) {
                                    elem.click();
                                } else {
                                    console.log(
                                        "%cfile input not found!",
                                        "color:red"
                                    );
                                }
                            }}>
                            Lisää kuvia raporttiin
                        </button>
                        <input
                            type="file"
                            multiple // user can select multiple files
                            accept="image/*" // accept only images
                            id="reportFileInput"
                            style={{display: "none"}}
                        />
                    </div>
                </div>
            </div>
            {submitting && (
                <div className="form-group text-center">
                    <div className="spinner-border text-info"></div>
                </div>
            )}
            {feedbackText && (
                <div className="form-group text-center">
                    <p>{feedbackText}</p>
                </div>
            )}
            {!submitting &&
                (newImages.length > 0 ||
                    (editedWorkReport &&
                        Object.keys(editedWorkReport).length > 0)) && (
                    <div className="row">
                        <div className="col submit-col mb-3">
                            <button
                                id="workReportSubmit"
                                onClick={(e) => {
                                    e.preventDefault();
                                    setFeedbackText();
                                    setSubmitting(true);
                                    submitReport();
                                }}
                                className="btn ap-button">
                                Tallenna muutokset työraporttiin
                            </button>
                        </div>
                    </div>
                )}
        </>
    );
};

export default EditReport;
