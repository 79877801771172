import app from "firebase/app";

var firebaseConfig = {
    apiKey: "AIzaSyBU0kCmF9esLOXPum7kIXTYjsTxgpHGKKw",
    authDomain: "asfaltti-beta.firebaseapp.com",
    databaseURL: "https://asfaltti-beta.firebaseio.com",
    projectId: "asfaltti-beta",
    storageBucket: "asfaltti-beta.appspot.com",
    messagingSenderId: "207019949244",
    appId: "1:207019949244:web:2515c2c761bd27d6c8e4b9",
    measurementId: "G-0DD1BGM1JG"
  };
  
  const Firebase = app.initializeApp(firebaseConfig);

  export default Firebase;
  