import React from "react";

/**
 * Even though it's named EditReivew, you can't actually edit anything
 * (at least not yet, though I don't think we want that anyways)
 */
const EditReview = ({review}) => {
    return (
        <div className="row">
            <div className="col">
                <div className="edit-block shadow">
                    <div className="form-group">
                        <label htmlFor="">Arviointi</label>
                    </div>
                    <div className="form-group">
                        <label htmlFor="">Työn laatu (1-5)</label>
                        <p>{review.score}</p>
                    </div>
                    <div className="form-group">
                        <label htmlFor="">Avoin palaute</label>
                        <p>{review.content}</p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default EditReview;
