import React, {useState} from "react";
import {
    Table,
    TableRow,
    TableHead,
    TableCell,
    TableBody,
} from "@material-ui/core";

import TablePagination from "@material-ui/core/TablePagination";

const UserTable = ({users, onUserSelected}) => {
    // no sorting yet, but let's build the headers in a way
    // that it is easy to add sorting should we want to
    const headers = [{label: "Sähköposti"}, {label: "Nimi"}];

    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [page, setPage] = useState(0);
    /* leaving this as an example here, we could render empty rows to match the rowsPerPage count
    const emptyRows =
        rowsPerPage - Math.min(rowsPerPage, users.length - page * rowsPerPage);

        {emptyRows > 0 && (
            <TableRow style={{height: 53 * emptyRows}}>
                <TableCell colSpan={6} />
            </TableRow>
        )}*/

    return (
        <>
            <Table className="shadow">
                <TableHead>
                    <TableRow>
                        {headers.map((column, index) => {
                            return (
                                <TableCell key={index}>
                                    {column.label}
                                </TableCell>
                            );
                        })}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {users
                        .slice(
                            page * rowsPerPage,
                            page * rowsPerPage + rowsPerPage
                        )
                        .map((data, index) => {
                            return (
                                <TableRow
                                    hover
                                    key={"user" + index}
                                    onClick={() => {
                                        onUserSelected(data);
                                    }}>
                                    <TableCell>{data.email}</TableCell>
                                    <TableCell>{data.name ?? ""}</TableCell>
                                </TableRow>
                            );
                        })}
                </TableBody>
            </Table>
            <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={users.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onChangePage={(e, newPage) => {
                    setPage(newPage);
                }}
                onChangeRowsPerPage={(e) => {
                    setRowsPerPage(parseInt(e.target.value, 10));
                    setPage(0);
                }}
            />
        </>
    );
};

export default UserTable;
