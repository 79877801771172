import React, {useState} from "react";
import Firebase, {FirebaseContext} from "../Firebase";
import {CircularProgress} from "@material-ui/core";
import {useHistory} from "react-router-dom";
require("firebase/auth");

const Login = () => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [loginError, setLoginError] = useState("");
    const [submitting, setSubmitting] = useState(false);

    const history = useHistory();

    const handleErrors = (error) => {
        if (error.code === "auth/invalid-email") {
            setLoginError("Tarkasta sähköposti");
        } else if (error.code === "auth/user-not-found") {
            setLoginError("Käyttäjää ei löytynyt");
        } else {
            setLoginError(error.message);
        }
    };

    return (
        <div className="container login-container">
            <div className="login-box shadow">
                {loginError && <p style={{color: "red"}}>{loginError}</p>}

                <form>
                    <div className="form-group">
                        <label htmlFor="inputEmail">Sähköposti</label>
                        <input
                            required
                            type="email"
                            className="form-control"
                            id="inputEmail"
                            aria-describedby="emailHelp"
                            placeholder="matti@meikalainen.com"
                            onChange={(text) => setEmail(text.target.value)}
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="inputPassword">Salasana</label>
                        <input
                            required
                            type="password"
                            className="form-control"
                            id="inputPassword"
                            placeholder="Salasana"
                            onChange={(text) => setPassword(text.target.value)}
                        />
                    </div>
                    {!submitting && (
                        <button
                            autoFocus
                            type="submit"
                            className="btn ap-button"
                            onClick={(e) => {
                                e.preventDefault();
                                console.log("Signing in with", email);
                                setLoginError("");
                                setSubmitting(true);
                                // sign out any user if already logged in
                                Firebase.auth()
                                    .signOut()
                                    .catch((error) => {
                                        console.log(
                                            "Error while signing out",
                                            error
                                        );
                                    });
                                Firebase.auth()
                                    .signInWithEmailAndPassword(email, password)
                                    .catch((error) => {
                                        console.log(
                                            "Error while signing in",
                                            error
                                        );
                                        handleErrors(error);
                                        setSubmitting(false);
                                    });
                            }}>
                            Kirjaudu
                        </button>
                    )}
                    {submitting && <CircularProgress />}
                </form>
                <div className="mt-2">
                    <a
                        href="#"
                        className="forgot-password-link"
                        onClick={(e) => {
                            history.push("/salasana-unohtui");
                        }}>
                        Unohtuiko salasana?
                    </a>
                </div>
            </div>
        </div>
    );
};

export default Login;
