import React, {useEffect, useState} from "react";
import Nav from "../Nav";
import UserTable from "./UserTable";
import UserInput from "./UserInput";
import UserDetails from "./UserDetails";
import UserFilter from "./UserFilter";

import Firebase, {userCollectionPath} from "../Firebase";
require("firebase/firestore");

const Users = () => {
    const [users, setUsers] = useState([]);
    const [filteredUsers, setFilteredUsers] = useState([]);
    const [selectedUser, setSelectedUser] = useState();
    const [userFilter, setUserFilter] = useState();

    useEffect(() => {
        const unsub = Firebase.firestore()
            .collection(userCollectionPath)
            .onSnapshot((snapshot) => {
                const usr = [];
                if (snapshot) {
                    snapshot.forEach((user) => {
                        const data = user.data();
                        // filter anonymous users out
                        if (data.email) {
                            usr.push(data);
                        }
                    });
                    setUsers(usr);
                    setFilteredUsers(usr);
                }
            });

        return () => {
            unsub();
        };
    }, []);

    useEffect(() => {
        let filtered = [];
        if (users.length > 0 && userFilter) {
            users.forEach((user) => {
                let passed = false;
                // compare in upper case, userFilter is already set is uppercase
                if (user.email.toUpperCase().includes(userFilter)) {
                    passed = true;
                }
                if (user.name?.toUpperCase().includes(userFilter)) {
                    passed = true;
                }

                if (passed) {
                    filtered.push(user);
                }
            });
            setFilteredUsers(filtered);
        } else {
            setFilteredUsers(users);
        }
    }, [userFilter, users]);

    const onUserSelected = (user) => {
        setSelectedUser(user);
    };

    const onSelectedUserUpdated = (user) => {
        setSelectedUser(user);
    };

    const onUserFilter = (text) => {
        // set filter as uppercase to make comparing a little easier
        setUserFilter(text.toUpperCase());
    };

    return (
        <>
            <Nav />
            <div className="container-fluid">
                <div className="row">
                    <div className="col mt-5 user-filter">
                        <UserFilter onUserFilter={onUserFilter} />
                    </div>
                </div>
                <div className="row">
                    <div className="col-6 mt-5 user-table">
                        <UserTable
                            users={filteredUsers}
                            onUserSelected={onUserSelected}
                        />
                    </div>
                    <div className="col-6 mt-5">
                        <UserDetails
                            user={selectedUser}
                            onSelectedUserUpdated={onSelectedUserUpdated}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-6 mt-5">
                        <UserInput />
                    </div>
                </div>
            </div>
        </>
    );
};

export default Users;
