import React, {useState, useEffect} from "react";

import Firebase from "../Firebase";
require("firebase/storage");

/**
 * imageRefences are images that are already uploaded into firebase storage
 * staticImages are images that are not yet uploaded
 */
const ImageLister = ({imageReferences = [], size, staticImages = []}) => {
    const [images, setImages] = useState([]);
    useEffect(() => {
        if (images.length !== imageReferences.length) {
            const refs = [];
            imageReferences.forEach((ref) => {
                // uploading with android generates whole storage references
                // which you cannot use here, but we can parse the correct URL from them.
                // example android ref: gs://asfaltti-beta.appspot.com/images/LgaH0z8GqBAXa3ghYvFW_1.jpg
                let s = ref.split(".com")[1];
                refs.push(s);
            });
            let getImagePromise = new Promise((resolve, reject) => {
                const urls = getImages(refs);
                resolve(urls);
                reject(new Error("Something went wrong."));

                setTimeout(() => reject(new Error("Get image timeout")), 5000);
            });

            getImagePromise.then((result) => {
                //console.log("Image promise finished", result);

                setImages(result);
            });

            async function getImages(references) {
                const nUrls = [];

                for (let r of references) {
                    await Firebase.storage()
                        .ref(r)
                        .getDownloadURL()
                        .then((url) => {
                            nUrls.push(url);
                        });
                }
                return nUrls;
            }
        }
    }, [imageReferences, images]);
    return (
        <>
            <div
                className="img-row"
                style={{justifyContent: size ? "" : "center"}}>
                {images.map((item, index) => {
                    return (
                        <a
                            href={item}
                            target="_blank"
                            rel="noreferrer"
                            key={index}>
                            <img
                                src={item}
                                style={{
                                    width: size ?? "140px",
                                    height: size ?? "140px",
                                    marginRight: "5px",
                                    marginBottom: "5px",
                                    objectFit: "cover",
                                }}
                            />
                        </a>
                    );
                })}
                {staticImages.map((item, index) => {
                    return (
                        <a
                            href={item}
                            target="_blank"
                            rel="noreferrer"
                            key={index}>
                            <img
                                src={item}
                                style={{
                                    width: size ?? "140px",
                                    height: size ?? "140px",
                                    marginRight: "5px",
                                    marginBottom: "5px",
                                    objectFit: "cover",
                                }}
                            />
                        </a>
                    );
                })}
            </div>
        </>
    );
};

export default ImageLister;
