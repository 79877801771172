import React, {useState, useCallback, useMemo} from "react";
import {Table, TableRow, TableCell, TableBody} from "@material-ui/core";
import TablePagination from "@material-ui/core/TablePagination";
import linq from "linq";

import {useHistory} from "react-router-dom";

import SortableHeader from "./SortableHeader";
import {TableData, SortingConfiguration, SortingType} from "./SortingTypes";
import {Link} from "react-router-dom";

const statusMap = {
    1: "Luotu",
    1.01: "Luonnos",
    1.1: "Tarjous tehty",
    1.15: "Hylätty",
    1.2: "Hyväksytty - Odottaa työtä",
    2: "Työn alla",
    3: "Valmis",
};

const statusColors = {
    1: "#ff6200",
    1.01: "#ff8133",
    1.1: "#0095ff",
    1.15: "#ff4040",
    1.2: "#4cdb39",
    2: "#fffc5c",
    3: "lightgray",
};

const TransactionTable = ({transactions, initializing, targetLink}) => {
    const [sortConfig, updateSortConfig] = useState<SortingConfiguration[]>([
        // sort by reporterName by default
        {propertyName: "reporterName", sortType: SortingType.Ascending},
    ]);

    const history = useHistory();

    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [page, setPage] = useState(0);

    const sortBy = useCallback(
        (propertyName: keyof TableData) => {
            let pendingChange = [...sortConfig];
            const index = pendingChange.findIndex(
                (config) => config.propertyName === propertyName
            );
            if (index > -1) {
                //Save the sortType
                var currentSortType = pendingChange[index].sortType;
                //Remove existing config
                pendingChange.splice(index, 1);
                //check if the sort type we saved is descending
                if (currentSortType === SortingType.Descending) {
                    pendingChange = [
                        //...pendingChange, uncomment to support multiple columns
                        {
                            propertyName: propertyName,
                            sortType: SortingType.Ascending,
                        },
                    ];
                }
            } else {
                pendingChange = [
                    //...pendingChange, uncomment to support multiple columns
                    {
                        propertyName: propertyName,
                        sortType: SortingType.Descending,
                    },
                ];
            }
            updateSortConfig([...pendingChange]);
        },
        [sortConfig]
    );

    const sortedRows = useMemo(() => {
        //Set up default ordering
        let sorted = linq.from(transactions).orderBy(() => 1);
        //Loop through the queue
        sortConfig.forEach((sortConfig) => {
            if (sortConfig.sortType === SortingType.Ascending) {
                sorted = sorted
                    .thenBy((dataRow) =>
                        dataRow[sortConfig.propertyName] === null ? -1 : 1
                    )
                    .thenBy((dataRow) => dataRow[sortConfig.propertyName]);
            } else {
                sorted = sorted
                    .thenByDescending((dataRow) =>
                        dataRow[sortConfig.propertyName] === null ? -1 : 1
                    )
                    .thenByDescending(
                        (dataRow) => dataRow[sortConfig.propertyName]
                    );
            }
        });
        return sorted.toArray();
    }, [sortConfig, transactions]);

    const formatDate = (timestamp) => {
        return new Date(timestamp).toLocaleDateString("fi-FI");
    };
    return (
        <>
            <Table>
                <SortableHeader sortBy={sortBy} sortConfig={sortConfig} />
                <TableBody>
                    {initializing && sortedRows.length === 0 && (
                        <TableRow>
                            <TableCell>Haetaan tilauksia...</TableCell>
                        </TableRow>
                    )}
                    {!initializing && sortedRows.length === 0 && (
                        <TableRow>
                            <TableCell>Ei tuloksia</TableCell>
                        </TableRow>
                    )}
                    {sortedRows
                        .slice(
                            page * rowsPerPage,
                            page * rowsPerPage + rowsPerPage
                        )
                        .map((data: any, index) => {
                            //console.log("sortedRow", data);

                            return (
                                <TableRow key={index}>
                                    <TableCell>{data.refId}</TableCell>
                                    <TableCell>{data.reporterName}</TableCell>
                                    <TableCell>{data.reporterCompany}</TableCell>
                                    <TableCell>{data.locationName}</TableCell>
                                    <TableCell>{data.address}</TableCell>
                                    <TableCell style={{minWidth: "130px"}}>
                                        {data.postalCode}
                                    </TableCell>
                                    <TableCell style={{minWidth: "120px"}}>
                                        {formatDate(data.timeStamp)}
                                    </TableCell>
                                    <TableCell
                                        style={{
                                            minWidth: "100px",
                                            backgroundColor:
                                                statusColors[data.state],
                                        }}>
                                        {statusMap[data.state]}
                                    </TableCell>
                                    <TableCell style={{minWidth: "100px"}}>
                                        {data.price ? data.price + " €" : ""}
                                    </TableCell>
                                    <TableCell>
                                        {targetLink ? (
                                            <>
                                                <a
                                                    target="_blank"
                                                    rel="noreferrer"
                                                    className="btn ap-button"
                                                    href={
                                                        "https://kohde.asfalttipartio.fi/?id=" +
                                                        data.key
                                                    }>
                                                    Avaa
                                                </a>
                                            </>
                                        ) : (
                                            <button
                                                className="btn ap-button"
                                                onClick={() => {
                                                    history.push({
                                                        pathname: "/muokkaa",
                                                        search: "?transaction=" + data.key,
                                                        state: {
                                                            transaction:
                                                                data.key,
                                                        },
                                                    });
                                                }}>
                                                Avaa
                                            </button>
                                        )}
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                </TableBody>
            </Table>
            <TablePagination
                rowsPerPageOptions={[10, 25, 50]}
                component="div"
                count={transactions.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onChangePage={(e, newPage) => {
                    setPage(newPage);
                }}
                onChangeRowsPerPage={(e) => {
                    setRowsPerPage(parseInt(e.target.value, 10));
                    setPage(0);
                }}
            />
        </>
    );
};

export default TransactionTable;
