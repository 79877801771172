import React, {useState, useEffect} from "react";

import ImageLister from "./ImageLister";
import Firebase, {offerCollectionPath, txCollectionPath} from "../Firebase";
import {downscaleImage} from "./io";
import {logEntry} from "./ChangeLog";
import {REPORTED, OFFERED, DRAFTED} from "./TransactionState";
import Switch from "react-switch";

require("firebase/firestore");
require("firebase/storage");

const otherServiceText = {
    CornerRockService: "Reunakivivauriot",
    ManholeService: "Kansistot",
    RoadSignService: "Liikennemerkkivauriot",
    PaintService: "Vauriot pihamerkinnöissä",
};

const services = [
    "CornerRockService",
    "ManholeService",
    "RoadSignService",
    "PaintService",
    "offer",
];

const EditOffer = ({offer, transaction}) => {
    const [editedOffer, setEditedOffer] = useState({
        otherServices: [],
        otherServiceImages: {},
    });

    const [uploadTasks, setUploadTasks] = useState({});

    const [submitting, setSubmitting] = useState(false);
    const [feedbackText, setFeedbackText] = useState();

    const [latestService, setLatestService] = useState();

    const [imageIndex, setImageIndex] = useState(0);

    const submitOffer = async () => {
        // use old ID if it exists, otherwise generate a new one
        let id =
            offer.key.length > 0
                ? offer.key
                : Firebase.firestore().collection(offerCollectionPath).doc().id;
        const oldServiceImages = {...offer.otherServiceImages};
        const data = {...offer, ...editedOffer};
        data.timeStamp = Date.now();

        const editedDownloadUrls = {};
        let index = imageIndex;
        for (let service of Object.keys(uploadTasks)) {
            console.log("Download URL service", service);
            let arr = uploadTasks[service] ?? [];
            console.log("arr", arr);
            const dlUrls = [];
            for (let image of arr) {
                const ref = Firebase.storage().ref(
                    id + "/" + id + "_" + index + ".jpg"
                );
                index++;
                const result = await ref.put(image);
                const compPath =
                    "gs://" +
                    result.metadata.bucket +
                    "/" +
                    result.metadata.fullPath;
                dlUrls.push(compPath);
            }

            editedDownloadUrls[service] = dlUrls;
        }
        setImageIndex(index);

        const mergedServiceImages = mergeServiceImages(
            oldServiceImages,
            editedDownloadUrls
        );

        // process other services, find any minus ones and remove them
        const processedServices = removeMinusServices(
            data.otherServices,
            mergedServiceImages
        );
        data.otherServices = processedServices.services;

        // set service images
        data.otherServiceImages = processedServices.images;
        data.key = id;
        // submitting via admin panel changes draft status
        data.draft = false;
        console.log("Submitting data:", data);
        const doc = Firebase.firestore()
            .collection(offerCollectionPath)
            .doc(id);

        // if the offer is new, set state as OFFERED, otherwise use the old state (just updating data)
        const newOffer = transaction.offer === null;
        let state = newOffer ? OFFERED : transaction.state;
        // don't let the state stay as REPORTED or DRAFTED
        if (state === REPORTED || state === DRAFTED) {
            state = OFFERED;
        }

        doc.set(data)
            .then(() => {
                console.log("Offer updated successfully");
                // update ref to transaction and update state
                updateReferenceAndState(transaction.key, doc, state);
                logEntry(
                    txCollectionPath,
                    transaction.key,
                    newOffer ? "Tarjous luotu" : "Tarjousta päivitetty",
                    transaction?.log ?? []
                );
                setSubmitting(false);
                setFeedbackText("Tiedot päivitetty.");
                // don't let the UI rollback our changes
                offer = data;
            })
            .catch((error) => {
                setFeedbackText(
                    "Virhe päivittäessä tietoja -- " + error.message
                );
                setSubmitting(false);
            });
    };

    const removeMinusServices = (services, serviceImages) => {
        const minus = [];
        // find minus ones
        for (let service of services) {
            if (service.includes("-")) {
                minus.push(service);
            }
        }
        // remove from services & serviceImages
        for (let service of minus) {
            let index = services.indexOf(service);
            services.splice(index, 1);
            let realService = service.substring(1, service.length);
            //console.log("actual service:", realService);
            delete serviceImages[realService];
        }

        //console.log("Processed arrays:", services, serviceImages);
        return {services: services, images: serviceImages};
    };

    const mergeServiceImages = (oldImages, newImages) => {
        //console.log("merging", oldImages, newImages);
        const merged = {};
        for (let service of services) {
            //console.log("service", service);
            merged[service] = [
                ...(oldImages[service] ?? []),
                ...(newImages[service] ?? []),
            ];
        }
        return merged;
    };

    const updateReferenceAndState = (txId, ref, state) => {
        Firebase.firestore()
            .collection(txCollectionPath)
            .doc(txId)
            .update({offer: ref, state: state})
            .then(() => {
                console.log("Transaction reference updated");
            })
            .catch((error) => {
                console.log(
                    "Error while updating transaction reference",
                    error
                );
            });
    };

    useEffect(() => {
        // add services from original offer to editedOffer,
        // so we can handle files correctly
        const edited = {...editedOffer};
        edited.otherServices = [...offer.otherServices];
        setEditedOffer(edited);
        // figure out first image index
        let index = 0;
        // add up all existing image array lengths
        for (let service in offer.otherServiceImages) {
            let arr = offer.otherServiceImages[service];
            index += arr.length;
        }
        setImageIndex(index);
    }, []);

    // handle attached images
    useEffect(() => {
        //console.log("file handler, and latest service", latestService);
        function handleFiles() {
            const f = this.files;
            console.log("the files", f);
            if (f) {
                // images objects to be uploaded
                const fs = [];
                // images to show in the UI
                const viewableImages = [];
                for (let file of f) {
                    //console.log("a file", file);
                    // 1Mb
                    if (file.size > 1000000) {
                        console.log("over 1Mb file");
                        // downscale images if they are over 1Mb in size
                        // a 2.5Mb file turned into a 77Kb file
                        // while testing, so I'd say it at least works
                        downscaleImage(file, (newFile) => {
                            fs.push(newFile);
                            const imageUrl = URL.createObjectURL(newFile);
                            viewableImages.push(imageUrl);
                            // merge old and new files
                            // I'd do this outside of the loop, but it won't work correctly because of
                            // the downscaleImage implementation... there probably is a way to do it though.

                            if (latestService) {
                                const tasks = {...uploadTasks};
                                // set upload tasks
                                if (tasks[latestService]) {
                                    tasks[latestService] = [
                                        ...tasks[latestService],
                                        ...fs,
                                    ];
                                } else {
                                    tasks[latestService] = fs;
                                }
                                //console.log("Setting tasks", tasks);
                                setUploadTasks(tasks);
                                const edited = {...editedOffer};
                                // set images to be shown
                                if (edited.otherServiceImages[latestService]) {
                                    edited.otherServiceImages[
                                        latestService
                                    ].push(imageUrl);
                                } else {
                                    edited.otherServiceImages[
                                        latestService
                                    ] = viewableImages;
                                }
                                //console.log("Setting edited offer", edited);
                                setEditedOffer(edited);
                                setLatestService();
                            }
                        });
                    } else {
                        fs.push(file);
                        const imageUrl = URL.createObjectURL(file);
                        viewableImages.push(imageUrl);
                        // merge old and new files
                        if (latestService) {
                            const tasks = {...uploadTasks};
                            // set upload tasks
                            if (tasks[latestService]) {
                                //console.log("Existing service found");
                                tasks[latestService] = [
                                    ...tasks[latestService],
                                    ...fs,
                                ];
                            } else {
                                //console.log("New service");
                                tasks[latestService] = fs;
                            }
                            setUploadTasks(tasks);
                            const edited = {...editedOffer};
                            // set images to be shown
                            if (edited.otherServiceImages[latestService]) {
                                edited.otherServiceImages[latestService].push(
                                    imageUrl
                                );
                            } else {
                                edited.otherServiceImages[latestService] = [
                                    imageUrl,
                                ];
                            }

                            setEditedOffer(edited);
                        }
                    }
                }
            } else {
                console.log("somethings wrong with the files");
            }
        }
        //console.log("attaching file handler");
        // type="file" input is not handled by react, we're gonnna have to use
        // the file API & attach an event listener to the DOM element
        const elem = document.getElementById("offerFileInput");
        if (elem) {
            elem.addEventListener("change", handleFiles, false);
        } else {
            console.log("%ccould not attach file handler!", "color:red");
        }
        // important! remove the event listener when latestService changes
        // otherwise there'll be duplicate images all over the place
        return () => {
            if (elem) elem.removeEventListener("change", handleFiles, false);
        };
        // re-run every time imageFiles change so we don't have any stale data
        // if the user adds images more than once
    }, [editedOffer.otherServiceImages, latestService]);

    // I'm using -service to indicate we want to remove this when we're updating to firebase
    const handleServiceSwitch = (service, checked) => {
        console.log("service", service, checked);
        const edited = {...editedOffer};
        if (checked) {
            // adding item
            edited.otherServices.push(service);
            // remove minus counterpart
            edited.otherServices = removeFromArray(
                "-" + service,
                edited.otherServices
            );
            setEditedOffer(edited);
        } else {
            // removing item
            edited.otherServices.push("-" + service);
            // remove normal counterpart
            edited.otherServices = removeFromArray(
                service,
                edited.otherServices
            );
            setEditedOffer(edited);
        }
    };

    const removeFromArray = (toBeRemoved, array) => {
        const index = array.indexOf(toBeRemoved);
        if (index > -1) {
            array.splice(index, 1);
            return array;
        } else {
            console.log("Item not found", toBeRemoved, array);
        }
        return array;
    };

    const containsAddedServices = () => {
        if (editedOffer.otherServices.length > 0) {
            for (let service of editedOffer.otherServices) {
                if (!service.includes("-")) {
                    if (!offer.otherServices.includes(service)) return true;
                } else {
                    if (
                        offer.otherServices.includes(
                            service.substring(1, service.length)
                        )
                    ) {
                        return true;
                    }
                }
            }
        }
        return false;
    };

    //console.log("edited offer", editedOffer);
    //console.log("image index", imageIndex);
    return (
        <div className="row">
            <div className="col">
                <div className="edit-block shadow">
                    <div className="form-group">
                        <label htmlFor="">
                            Tarjous {offer.draft ? "(luonnos)" : ""}
                        </label>
                    </div>
                    <div className="form-group">
                        <label htmlFor="">Toimitusaika</label>
                        <input
                            type="text"
                            className="form-control"
                            value={
                                editedOffer?.deliveryDate ?? offer.deliveryDate
                            }
                            onChange={(e) => {
                                const value = e.target.value;

                                const edited = {
                                    ...editedOffer,
                                };
                                edited.deliveryDate = value;
                                setEditedOffer(edited);
                            }}
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="">Hinta (€)</label>
                        <input
                            type="number"
                            className="form-control"
                            value={editedOffer?.price ?? offer.price}
                            onChange={(e) => {
                                const value = e.target.value;

                                const edited = {
                                    ...editedOffer,
                                };
                                edited.price = value;
                                setEditedOffer(edited);
                            }}
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="">Hyväksytty</label>
                        <p>
                            {/* if no action is taken on the offer, show "Ei valintaa", if an action
                    is taken, show "Kyllä" or "Ei" depending on the choice */}
                            {offer.accepted !== null
                                ? offer.accepted
                                    ? "Kyllä"
                                    : "Ei"
                                : "Ei valintaa"}
                        </p>
                    </div>
                    <div className="form-group">
                        <label htmlFor="">Korjauksen tiedot</label>
                        <textarea
                            className="form-control"
                            value={
                                editedOffer?.mainContent ?? offer.mainContent
                            }
                            rows={3}
                            onChange={(e) => {
                                const value = e.target.value;

                                const edited = {
                                    ...editedOffer,
                                };
                                edited.mainContent = value;
                                setEditedOffer(edited);
                            }}
                        />
                    </div>
                    {offer.otherServiceImages["offer"] && (
                        <div className="form-group">
                            <label htmlFor="">Kuvat</label>
                        </div>
                    )}
                </div>

                <div className="edit-block image-block">
                    <ImageLister
                        imageReferences={
                            offer.otherServiceImages["offer"] ?? []
                        }
                    />
                    {editedOffer.otherServiceImages["offer"]?.length > 0 && (
                        <>
                            <p style={{marginTop: "7px"}}>Lisätyt kuvat:</p>
                            <ImageLister
                                staticImages={
                                    editedOffer.otherServiceImages["offer"]
                                }
                            />
                        </>
                    )}
                    <button
                        className="btn ap-button"
                        onClick={(e) => {
                            const elem = document.getElementById(
                                "offerFileInput"
                            );
                            setLatestService("offer");
                            if (elem) {
                                elem.click();
                            } else {
                                console.log(
                                    "%cfile input not found!",
                                    "color:red"
                                );
                            }
                        }}>
                        Lisää kuvia tarjoukseen
                    </button>
                    <input
                        type="file"
                        multiple // user can select multiple files
                        accept="image/*" // accept only images
                        id="offerFileInput"
                        style={{display: "none"}}
                    />
                </div>

                <div className="edit-block shadow">
                    {Object.keys(otherServiceText).map((service, index) => {
                        const imageArray =
                            offer.otherServiceImages[service] ?? [];
                        const statics =
                            editedOffer.otherServiceImages[service] ?? [];

                        let contained = false;
                        if (editedOffer?.otherServices?.includes(service)) {
                            contained = true;
                        }
                        if (
                            editedOffer?.otherServices?.includes("-" + service)
                        ) {
                            contained = false;
                        } else if (offer.otherServices.includes(service)) {
                            contained = true;
                        }
                        return (
                            <div className="service-container" key={index}>
                                <div className="switch-row">
                                    <p>{otherServiceText[service]}</p>
                                    <Switch
                                        onChange={(checked) => {
                                            handleServiceSwitch(
                                                service,
                                                checked
                                            );
                                        }}
                                        checked={contained}
                                    />
                                </div>
                                {contained && (
                                    <div className="service-input-row">
                                        <div
                                            className="service-input-images"
                                            onClick={() => {
                                                console.log(
                                                    "more pics",
                                                    service
                                                );
                                                const elem = document.getElementById(
                                                    "offerFileInput"
                                                );
                                                setLatestService(service);
                                                if (elem) {
                                                    elem.click();
                                                } else {
                                                    console.log(
                                                        "Could't find fileInput handler"
                                                    );
                                                }
                                            }}>
                                            <p>Lisää kuvia</p>
                                        </div>
                                        <ImageLister
                                            imageReferences={imageArray}
                                            size={50}
                                            staticImages={statics}
                                        />
                                        <input
                                            type="file"
                                            multiple // user can select multiple files
                                            accept="image/*" // accept only images
                                            id={service + "FileInput"}
                                            style={{display: "none"}}
                                        />
                                    </div>
                                )}
                            </div>
                        );
                    })}

                    <div className="form-group mt-3">
                        <label htmlFor="">
                            Piha-alueen muut havaitut vauriot
                        </label>
                        <textarea
                            className="form-control"
                            value={
                                editedOffer?.otherContent ?? offer.otherContent
                            }
                            rows={3}
                            onChange={(e) => {
                                const value = e.target.value;

                                const edited = {
                                    ...editedOffer,
                                };
                                edited.otherContent = value;
                                setEditedOffer(edited);
                            }}
                        />
                    </div>
                </div>
                {submitting && (
                    <div className="form-group text-center">
                        <div className="spinner-border text-info"></div>
                    </div>
                )}
                {feedbackText && (
                    <div className="form-group text-center">
                        <p>{feedbackText}</p>
                    </div>
                )}
                {!submitting &&
                    editedOffer &&
                    (containsAddedServices() ||
                        editedOffer.otherServiceImages["offer"]?.length > 0 ||
                        Object.keys(editedOffer).length > 2) && (
                        <div className="row">
                            <div className="col submit-col mb-3">
                                <button
                                    id="offerSubmit"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        setFeedbackText();
                                        setSubmitting(true);
                                        submitOffer();
                                    }}
                                    className="btn ap-button">
                                    Tallenna muutokset tarjoukseen
                                </button>
                            </div>
                        </div>
                    )}
            </div>
        </div>
    );
};

export default EditOffer;
